import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import renderHTML from 'react-render-html';

import Container from 'components/grid/container';
import MaxWidth from 'components/grid/maxWidth';
import TextImageLockup from 'components/layout/textImageLockup';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';

const Border = styled(Box)`
  border-bottom: 1px solid ${p => p.theme.greyLight};
`;

const NoImageWrapper = styled(MaxWidth)`
  text-align: center;
`;

const TextWrapper = styled(Box)`
  color: ${p => p.theme.grey};
`;

const ServiceRows = ({ data, ...rest }) => {
  const serviceArray = Object.keys(data).map(i => data[i]);

  return (
    <Container mt={[7, 7, `100px`]} mb={[7, 7, `100px`]} {...rest}>
      <Border mb={[7, 7, `100px`]} />

      {serviceArray.map((row, i) => {
        let right = false;
        if (i % 2 === 1) {
          right = true;
        }

        const text = <Text style="h4" margin="0" weight="normal">{renderHTML(row.subtitle)}</Text>;

        return (
          <Box key={row.title} mb={[10, `100px`, `130px`]}>
            {row.image ? (
              <TextImageLockup
                right={right}
                title={row.title}
                image={<Img fluid={row.image.src.childImageSharp.fluid} alt={row.image.alt} />}
              >
                {text}
              </TextImageLockup>
            ) : (
              <NoImageWrapper>
                <Heading rank="h2" margin="0">{row.title}</Heading>
                <TextWrapper py={5}>
                  {text}
                </TextWrapper>
              </NoImageWrapper>
            )}
          </Box>
        );
      })}
    </Container>
  );
};

ServiceRows.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServiceRows;

export const query = graphql`
  fragment ServiceRowOneFragment on MarkdownRemark {
    frontmatter {
      main {
        one {
          title
          subtitle
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1140) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            alt
          }
        }
      }
    }
  }

  fragment ServiceRowTwoFragment on MarkdownRemark {
    frontmatter {
      main {
        two {
          title
          subtitle
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1140) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            alt
          }
        }
      }
    }
  }

  fragment ServiceRowThreeFragment on MarkdownRemark {
    frontmatter {
      main {
        three {
          title
          subtitle
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1140) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`;
