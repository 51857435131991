import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ServicePage from 'templates/servicePage';
import ServiceRows from 'components/layout/serviceRows';
import Browser from 'components/images/browser';

const FullCoachingSessions = ({ data }) => {
  const {
    markdownRemark: { frontmatter: { 
      main,
    } },
  } = data;

  return (
    <ServicePage 
      data={data}
      heroImage={(
        <Browser>
          <iframe 
            src="https://player.vimeo.com/video/294755323?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=86505" 
            frameBorder="0" 
            title="David Moyes 1v1 Pressing" 
            webkitallowfullscreen 
            mozallowfullscreen 
            allowFullScreen 
          />
        </Browser>
      )}
    >
      <ServiceRows data={main} />
    </ServicePage>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...LayoutFragment
      ...ServicePageFragment
      ...ServiceHeroFragment
      ...ServiceRowOneFragment
      ...ServiceRowTwoFragment
      ...ServiceRowThreeFragment
    }
  }
`;

FullCoachingSessions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FullCoachingSessions;