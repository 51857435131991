import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import renderHtml from 'react-render-html';

import Heading from 'components/typography/heading';

const TextWrapper = styled(Box)`
  color: ${p => p.theme.grey};
`;

const TextImageLockup = ({ right, title, image, children }) => {
  let order;
  const imageSpacingProps = {};
  const imageSpacing = [0, 0, 0, 0, `100px`];

  if (right) {
    order = 2;
    imageSpacingProps.pr = imageSpacing;
  } else {
    imageSpacingProps.pl = imageSpacing;
  }

  return (
    <Flex flexWrap="wrap" alignItems="center">
      <Box width={[1, 1, 1, 1, 4 / 12]} order={[0, 0, 0, 0, order]}>
        <Heading rank="h2" margin="0">{renderHtml(title)}</Heading>
        <TextWrapper py={5}>
          {children}
        </TextWrapper>
      </Box>

      <Box width={[1, 1, 1, 1, 8 / 12]} pt={[5, 5, 10, 10, 0]} {...imageSpacingProps}>
        {image}
      </Box>
    </Flex>
  );
};

TextImageLockup.propTypes = {
  right: PropTypes.bool,
  title: PropTypes.string,
  image: PropTypes.object,
  children: PropTypes.object,
};

export default TextImageLockup;
